import React, { useState, useRef } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';
import PauseIcon from '@mui/icons-material/Pause';
import MDTypography from "components/MDTypography";

const DynamicTable = ({ data, source }) => {
  const [playingIndex, setPlayingIndex] = useState(null);  // State to track the currently playing row
  const audioRef = useRef(null); // Reference to the audio element

  if (!data.length) return null;
  
  // Helper function to convert Unix timestamp to UTC string
const formatUnixToUTC = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  return date.toUTCString();
};

  // Extract table headers from the keys of the first object
  const headers = Object.keys(data[0]).filter(header => header !== "mp3_url" && header !== "id" && header !== "service");

  // Helper function to convert snake_case to normal words
const formatHeader = (header) => {
  return header.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase());
};

  // Handle play/pause button logic
  const handlePlayPause = (url, index) => {
    if (audioRef.current) {
      if (playingIndex === index) {
        // If currently playing the same audio, pause it
        audioRef.current.pause();
        setPlayingIndex(null);
      } else {
        // Pause any currently playing audio before switching
        if (playingIndex !== null) {
          audioRef.current.pause();
        }
        // Set new audio source and play it
        audioRef.current.src = url;
        audioRef.current.play();
        setPlayingIndex(index);
      }
    }
  };


  // Download audio
  const handleDownload = async (audioUrl) => {
    try {
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'synthesized_speech.mp3');
      document.body.appendChild(link);
      link.click();
  
      // Clean up the URL object
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed', error);
    }
  };


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
        <TableHead sx={{ display: 'table-header-group' }}>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header} align="center" style={{ 
                fontWeight: "bold", 
                padding: "8px", 
                width: `${100 / (headers.length + (source === "executions" ? 2 : 0))}%`, // Adjust width for new columns
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}>
                <MDTypography variant="h6">
                  {formatHeader(header)}
                </MDTypography>
              </TableCell>
            ))}
            {/* Conditionally render Play and Download columns only if source is "executions" */}
            {source === "executions" && (
              <>
                <TableCell align="center" style={{ fontWeight: "bold", padding: "8px" }}>
                  <MDTypography variant="h6">Play</MDTypography>
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold", padding: "8px" }}>
                  <MDTypography variant="h6">Download</MDTypography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((header) => (
                <TableCell key={header} align="center" style={{ 
                    padding: "8px", 
                    width: `${100 / (headers.length + (source === "executions" ? 2 : 0))}%`, // Adjust width for new columns
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}>
                  <MDTypography variant="body">
                  {header === 'start_date' || header === 'end_date'
                      ? formatUnixToUTC(row[header])  // Convert Unix timestamp to UTC for specific columns
                      : row[header]} 
                  </MDTypography>
                </TableCell>
              ))}
              {/* Conditionally render Play and Download buttons if source is "executions" */}
              {source === "executions" && (
                <>
                  <TableCell align="center" style={{ padding: "8px" }}>
                    <IconButton onClick={() => handlePlayPause(row.mp3_url, rowIndex)}>
                    {playingIndex === rowIndex ? (
                        <PauseIcon color="error" />
                      ) : (
                        <PlayArrowIcon color="primary" />
                      )}
                      {/* <PlayArrowIcon color={playingIndex === rowIndex ? "error" : "primary"} /> */}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" style={{ padding: "8px" }}>
                    <IconButton onClick={() => handleDownload(row.mp3_url)}>
                      <DownloadIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Hidden audio element for playback */}
      <audio ref={audioRef} />
    </TableContainer>
  );
};

DynamicTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  source: PropTypes.string,  // Added new prop type for source
};

export default DynamicTable;

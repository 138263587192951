import { useState, useEffect } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { useNavigate } from 'react-router-dom';
import './sign-in.css';
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";


// import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";

import { useNotification } from 'context/notification';

import { get, post } from "services/api"
import MDBox from "components/MDBox";
import { CircularProgress } from "@mui/material";

const Login = () => {

  const [showLoader, setShowLoader] = useState(false);
  useEffect(
    () => {
      logout()
    },
    []
  )


  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const handleLoginSuccess = async (response) => {

    try {
      const idToken = response.credential;

      let url = "https://api.dhwani.xyz/login"
      let requestBody = {
        "credential": idToken
      }

      setShowLoader(true)

      logout()
      const responseData = await post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      });

      setShowLoader(false)
      showNotification("success", "Successfully logged in!!!")
      navigate('/speech');
    }
    catch (error) {
      setShowLoader(false)
      showNotification("error", "Some error occured in login, Please try again")
      console.log("error occurred", error)
    }
  };

  const logout = async () => {

    let url = "https://api.dhwani.xyz/logout"

    const responseData = await post(url, {}, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true
    });
  }

  const handleLoginFailure = (error) => {
    console.error("Login failed:", error);
    showNotification("error", "Your Login Failed")
  };

  return (
    <div className="sign-in-page">
      <div className="left-container"></div>
      <div className="right-container">
        <div className="right-container__box">

        {showLoader && (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height to ensure centering
          }}
        >
          <CircularProgress />
        </MDBox>
      )}

      { !showLoader && (
        <>
          <div className="right-container-box">
            <h2 className="right-container__h2">Nice to see you!</h2>
            <p className="right-container__p">Use your google auth to sign in</p>
          </div>
          <GoogleOAuthProvider clientId="275828416055-geun2553q93e5j8727r3h89tmjo5hfo6.apps.googleusercontent.com">
            <Grid container spacing={0} justifyContent="center" sx={{ mt: 1, mb: 2 }} style={{}}>
              {/* <Grid item xs={12} lg={6}> */}
                <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} logo_alignment="left" theme="filled_blue" size="large" width="100%" // Adjusts to 100% of the container width
          sx={{
            maxWidth: { xs: '100%', sm: '80%', md: '60%', lg: '50%' }, // Responsive width adjustments
          }} />
              {/* </Grid> */}

            </Grid>
          </GoogleOAuthProvider>
          </>
          )
}
        </div>
      </div>
    </div>
  );


};

export default Login;

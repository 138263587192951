import React, { useRef, useState, useEffect } from 'react';

import MDBox from "components/MDBox";
import Footer from "components/Footer";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { Modal, Box, Button, Typography, LinearProgress, useTheme, IconButton } from '@mui/material';
import CustomProgressBar from 'components/ProgressBar'
import CloseIcon from '@mui/icons-material/Close';

const SynthesisSuccessContainer = ({ handleClose, modalAudioUrl, darkMode }) => {

  // Set duration when metadata is loaded
  const handleLoadedMetadata = () => {
    if (modalAudioRef.current) {
      setDuration(modalAudioRef.current.duration); // Set the duration of the audio
    }
  };


  // Play the audio
  const handlePlay = () => {
    if (modalAudioRef.current) {
      if (isPlaying) {
        modalAudioRef.current.pause(); // Pause the audio
      } else {
        modalAudioRef.current.play();  // Play the audio
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  // Download audio
  const handleDownload = async () => {
    try {
      const response = await fetch(modalAudioUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'synthesized_speech.mp3');
      document.body.appendChild(link);
      link.click();
  
      // Clean up the URL object
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed', error);
    }
  };

  // State to manage progress and duration
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);


  useEffect(() => {
  
    // Handle time update using setInterval to simulate continuous progress update
    const timer = setInterval(() => {
      if (modalAudioRef.current && modalAudioRef.current.currentTime && duration > 0) {
        setProgress((modalAudioRef.current.currentTime / duration) * 100); // Calculate progress based on currentTime and duration
      }
    }, 500); // Update every 500ms
  
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [duration]);

  const modalAudioRef = useRef(null);

  return (
  <MDBox sx={{
    marginTop: "40px",
    width: "auto",
    bgcolor: darkMode ? '#222' : '#fff',
    color: darkMode ? '#f1f1f1' : '#000',
    padding: "40px",
    borderRadius: '12px',
    boxShadow: darkMode
      ? '0px 10px 30px rgba(0, 0, 0, 0.5)'
      : '0px 10px 30px rgba(0, 0, 0, 0.2)',
    outline: 'none',
    flexDirection: 'column',  // Arrange children vertically
    position: 'relative',     // Allow absolute positioning
  }}>
  <IconButton
    onClick={handleClose}
    sx={{
      color: darkMode ? '#fff' : '#000',

  position: 'absolute',    // Position it absolutely
  right: '16px',           // Align it to the right side
  top: '16px',             // Adjust top padding
    }}
  >
  <CloseIcon />
  </IconButton>
    <MDTypography id="synthesis-modal-title" variant="h6" component="h2" sx={{ fontWeight: 700, color: darkMode ? '#ffffff' : '#000000' }}>
      Speech Synthesis Completed!
    </MDTypography>
    <MDTypography sx={{ mt: 2, color: darkMode ? '#bbbbbb' : '#555555', lineHeight: 1.6 }}>
      You can listen to the synthesized speech below. It will also be available in your dashboard for one month before it is deleted.
    </MDTypography>
      <>
        <audio
          ref={modalAudioRef}
          src={modalAudioUrl}
          style={{ display: 'none' }}
          onLoadedMetadata={handleLoadedMetadata} // Set duration once metadata is loaded
        />
        <MDBox sx={{ mt: 2 }}>
          <CustomProgressBar progress={progress} /> {/* Pass the progress value */}
          <MDTypography variant="body2" color="textSecondary" align="right">
            {`${Math.floor((progress / 100) * duration)} / ${Math.floor(duration)} sec`}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained"
            aria-label="Play synthesized speech as MP3"
           sx={{
            backgroundColor: darkMode ? '#007BFF' : '#007BFF',
            color: '#fff',
            padding: '8px 16px',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }} onClick={handlePlay}>
            {isPlaying ? 'Pause' : 'Play'}
          </Button>
          <Button variant="contained" 
            aria-label="Download synthesized speech as MP3"
            sx={{
            backgroundColor: darkMode ? '#007BFF' : '#007BFF',
            color: '#fff',
            padding: '8px 16px',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }} onClick={handleDownload}>
            Download
          </Button>
        </MDBox>
      </>
  </MDBox>)
};

// PropTypes validation
SynthesisSuccessContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalAudioUrl: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default SynthesisSuccessContainer;

import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import MDSnackbar from "components/MDSnackbar";

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    type: "info",
    icon: "notifications",
    title: "Notification",
    content: "",
    dateTime: "Just now",
  });

  const showNotification = useCallback((type, content, title = "Notification", icon = null) => {
    setNotification({
      open: true,
      type,
      icon: icon || getDefaultIcon(type),
      title,
      content,
      dateTime: "Just now",
    });
  }, []);

  const closeNotification = useCallback(() => {
    setNotification((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <MDSnackbar
        color={notification.type}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        dateTime={notification.dateTime}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite={notification.type !== "info"}
      />
    </NotificationContext.Provider>
  );
};

const getDefaultIcon = (type) => {
  switch (type) {
    case "success":
      return "check";
    case "warning":
      return "star";
    case "error":
      return "warning";
    default:
      return "notifications";
  }
};

// Validate the children prop
NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

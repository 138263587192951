import PricingPage from "./PricingPage"; // Adjust the path as necessary

import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";

import Footer from "components/Footer";
import { Container, Grid, Typography, Box } from "@mui/material";


import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";

const Pricing = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;


  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <Container> */}
      <PricingPage darkmode={darkMode} />

      <MDBox mb={3}>
        <Grid container spacing={3} style={{ paddingTop: "40px" }}>
          <Grid item xs={12} lg={12}>
            <MDTypography variant="h5" zIndex={2} style={{ textAlign: "left" }}>
              Understanding words conversion in engines
            </MDTypography>

            <MDTypography variant="body1" zIndex={2} style={{ textAlign: "left" }}>
              The pricing described in each plan shows the count of words in standard engine. The
              following are the 4 available engines currently to process text to speech - Standard,
              Neural, Generative, Long-Form. If you are assigned 100 words in standard engine - You
              can convert either 100 words via standard engine or 25 words via neural or 12 words
              via generative or 4 words via long-form engine
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      {/* </Container> */}
      <div style={{paddingTop: "40px"}}></div>
      <Footer />
    </DashboardLayout>
  );
};

export default Pricing;

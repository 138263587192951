import React, { useRef, useState, useEffect } from "react";

import PropTypes from "prop-types";
import {
  Modal,
  Box,
  Button,
  Typography,
  LinearProgress,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CustomProgressBar from "components/ProgressBar";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const LearnMoreModal = ({ isOpen, handleClose, darkMode }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title" style={{
        backgroundColor: darkMode ? '#222' : '#fff',
        fontSize: "30px"
      }}>{"Understand how you're charged for words"}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(1),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{
        backgroundColor: darkMode ? '#222' : '#fff',
      }}>
        {/* <DialogContentText id="alert-dialog-description"> */}
          <MDBox>
            <MDTypography variant="body1">
              <strong>Note:</strong> Every time you synthesize speech, the words will be deducted
              from your available balance based on the engine you choose. All engines have a
              standard word count equivalent:
            </MDTypography>

            <MDBox component="ul" sx={{ paddingLeft: "16px", listStyleType: "disc" }}>
              <MDTypography component="li" variant="body1">
                <strong>Generative Engine:</strong> 8 standard words = 1 generative word
              </MDTypography>
              <MDTypography component="li" variant="body1">
                <strong>Long-Form Engine:</strong> 25 standard words = 1 long-form word
              </MDTypography>
              <MDTypography component="li" variant="body1">
                <strong>Neural Engine:</strong> 4 standard words = 1 neural word
              </MDTypography>
            </MDBox>

            <MDTypography variant="body1" sx={{ marginTop: "16px" }}>
              The Words Left section represents how many words you can synthesize using any engine.
              For simplicity, the words assigned in your subscription are measured as{" "}
              <strong>standard words</strong>. The engine you choose will adjust the words deducted
              according to its conversion rate.
            </MDTypography>

            <MDTypography variant="h6" sx={{ marginTop: "16px" }}>
              Example:
            </MDTypography>

            <MDTypography variant="body1">
              <strong>If you use 100 words in the Standard engine:</strong>
            </MDTypography>

            <MDBox component="ul" sx={{ paddingLeft: "16px", listStyleType: "disc" }}>
              <MDTypography component="li" variant="body1">
                25 words will be deducted from your neural balance.
              </MDTypography>
              <MDTypography component="li" variant="body1">
                12 words will be deducted from your generative balance.
              </MDTypography>
              <MDTypography component="li" variant="body1">
                4 words will be deducted from your long-form balance.
              </MDTypography>
            </MDBox>

            <MDTypography variant="body1" sx={{ marginTop: "16px" }}>
              This means, using the <strong>Standard engine</strong> will allow you to synthesize up
              to 25 times more words than using the <strong>Neural engine</strong> for the same word
              balance.
            </MDTypography>
          </MDBox>
        {/* </DialogContentText> */}
      </DialogContent>
    </Dialog>

    // <Dialog open={isOpen} onClose={handleClose} aria-labelledby="learn-more-modal">

    //   <MDBox sx={{
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 400,
    //     borderRadius: '12px',
    //     padding: theme.spacing(4),
    //     outline: 'none',
    //   }}>
    //             <IconButton
    //       onClick={handleClose}
    //       sx={{
    //         position: 'absolute',
    //         top: theme.spacing(1),
    //         right: theme.spacing(1),
    //       }}
    //     >
    //       <CloseIcon />
    //     </IconButton>

    //     <MDBox>
    //                 <MDTypography variant="body1">
    //                   <strong>Note:</strong> Every time you synthesize speech, the words will be
    //                   deducted from your available balance based on the engine you choose. All
    //                   engines have a standard word count equivalent:
    //                 </MDTypography>

    //                 <MDBox component="ul" sx={{ paddingLeft: "16px", listStyleType: "disc" }}>
    //                   <MDTypography component="li" variant="body1">
    //                     <strong>Generative Engine:</strong> 1 standard word = 12 generative words
    //                   </MDTypography>
    //                   <MDTypography component="li" variant="body1">
    //                     <strong>Long-Form Engine:</strong> 1 standard word = 4 long-form words
    //                   </MDTypography>
    //                   <MDTypography component="li" variant="body1">
    //                     <strong>Neural Engine:</strong> 1 standard word = 25 neural words
    //                   </MDTypography>
    //                 </MDBox>

    //                 <MDTypography variant="body1" sx={{ marginTop: "16px" }}>
    //                   The Words Left section represents how many words you can synthesize using any
    //                   engine. For simplicity, the words assigned in your subscription are measured
    //                   as <strong>standard words</strong>. The engine you choose will adjust the
    //                   words deducted according to its conversion rate.
    //                 </MDTypography>

    //                 <MDTypography variant="h6" sx={{ marginTop: "16px" }}>
    //                   Example:
    //                 </MDTypography>

    //                 <MDTypography variant="body1">
    //                   <strong>If you use 100 words in the Standard engine:</strong>
    //                 </MDTypography>

    //                 <MDBox component="ul" sx={{ paddingLeft: "16px", listStyleType: "disc" }}>
    //                   <MDTypography component="li" variant="body1">
    //                     25 words will be deducted from your neural balance.
    //                   </MDTypography>
    //                   <MDTypography component="li" variant="body1">
    //                     12 words will be deducted from your generative balance.
    //                   </MDTypography>
    //                   <MDTypography component="li" variant="body1">
    //                     4 words will be deducted from your long-form balance.
    //                   </MDTypography>
    //                 </MDBox>

    //                 <MDTypography variant="body1" sx={{ marginTop: "16px" }}>
    //                   This means, using the <strong>Standard engine</strong> will allow you to
    //                   synthesize up to 25 times more words than using the{" "}
    //                   <strong>Neural engine</strong> for the same word balance.
    //                 </MDTypography>
    //               </MDBox>
    //   </MDBox>
    // </Dialog>
  );
};

// PropTypes validation
LearnMoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default LearnMoreModal;

import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Modal,
  Button,
  Select,
  MenuItem,
  Avatar,
  Typography,
  Box,
  InputLabel,
  CircularProgress,
  IconButton
} from "@mui/material";

import CustomProgressBar from 'components/ProgressBar'
import SampleVoices from "./sampleVoices";

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from "@mui/system";
import Divider from '@mui/material/Divider';

import CloseIcon from '@mui/icons-material/Close';


import { get, post } from "services/api";
import { useNavigate } from "react-router-dom";
import { useNotification } from "context/notification";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SynthesisSuccessContainer from "./synthesisSuccessContainer";

import { useMaterialUIController } from "context";
import AsyncSynthesisModal from "./asyncSynthesisModal";


const CoreSpeechLayout = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const audioRef = useRef(null);

  const [selectedEngine, setSelectedEngine] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedVoice, setSelectedVoice] = useState({});
  const [textValue, setTextValue] = useState("");
  const [engineData, setEngineData] = useState([]);
  const [voiceData, setVoiceData] = useState([]);
  const [langaugeEngineMapping, setLangaugeEngineMapping] = useState([]);
  const [fileName, setFileName] = useState(null);

  const [isAsyncModalOpen, setIsAsyncModalOpen] = useState(false);
  const [modalAudioUrl, setModalAudioUrl] = useState("");

  const [uploadedFileId, setUploadedFileId] = useState("");
  const [selectedEngineId, setSelectedEngineId] = useState("");
  const [selectedVoiceId, setSelectedVoiceId] = useState("");
  const [selectedLanguageCode, setselectedLanguageCode] = useState({});

  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));

  const inputFileRef = useRef(null);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAsyncModalClose = () => {
    setIsAsyncModalOpen(false);
  };



  const handleAsyncSpeechSynthesisComplete = () => {
    setIsAsyncModalOpen(true); // Open modal when synthesis is done
  };

  const getLanguageEngineMapping = async () => {
    try {
      const response = await get("https://api.dhwani.xyz/secure/languages/voices/mapping", {
        withCredentials: true,
      });
      const languageEngineMappingResponse = response.data;

      setLangaugeEngineMapping(languageEngineMappingResponse);

      let usEnglish = languageEngineMappingResponse.find(language => language.language_name === "US English");

      if(usEnglish) {

        let generativeEngineDefault = usEnglish.engines.find(engine => engine.engine_name === 'Generative')

        setSelectedLanguage(usEnglish)
        setselectedLanguageCode(usEnglish.language_code)
        setEngineData(usEnglish.engines);
        setSelectedEngine(generativeEngineDefault)
        setSelectedEngineId(generativeEngineDefault.engine_id);

        getVoicesFromLanguageAndEngine(usEnglish.language_code, generativeEngineDefault.engine_id);
      }
      
    } catch (error) {
      console.error("Error fetching language:", error);

      if (error.response.status === 401) {
        console.log("user is unauthorized here");

        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }

      showNotification("error", error.response.data)
    }
  };

  const getVoicesFromLanguageAndEngine = async (languageCode, engineId) => {
    try {
      const response = await get(
        `https://api.dhwani.xyz/secure/voices/language/${languageCode}/engine/${engineId}`,
        {
          withCredentials: true,
        }
      );
      const voiceData = response.data;

      setVoiceData(voiceData);
    } catch (error) {
      console.error("Error fetching dashboard:", error);

      if (error.response.status === 401) {
        console.log("user is unauthorized here");

        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }
    }
  };

  useEffect(() => {

    if(langaugeEngineMapping.length === 0) {

      // Call the language engine mapping function
      getLanguageEngineMapping();
    }
  
  }, []);

  const AvatarContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  }));

  const handleFileUpload = async (file) => {
    if (file) {
      // Handle file upload logic
      setFileName(file.name);

      const formData = new FormData();
      formData.append("file", file);

      try {
        // Send the file to the backend using axios
        const response = await post(
          "https://api.dhwani.xyz/secure/executionfileupload/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          // Handle successful upload
          setUploadedFileId(response.data.id);

          showNotification("success", "Successfully uploaded file");
        } else {
          // Handle unexpected response status
          showNotification("error", "Some error occured in file upload");
        }
      } catch (error) {
        if (error.response.status === 401) {
          showNotification("error", "Unable to authenticate. Please try logging in again");
          navigate("/authentication/sign-in");
        }

        showNotification("error", "Some error occurred in file upload");
      }
    }
  };

  const initiateSpeechSynthesis = async () => {
    console.log("triggered speech synthesis");

    if (selectedVoiceId === "" || selectedEngineId === "" || selectedLanguageCode === "") {
      // Please make sure you have selected voices, engine and language

      showNotification("error", "Please make sure you have selected voices, engine and language");
      return;
    }

    if (textValue !== "" && uploadedFileId !== "") {
      // Either use text box or upload file, not both

      showNotification("error", "Either use text box or upload file, not both");
      return;
    }

    if (textValue !== "" && textValue.length > 5010) {
      showNotification("error", "max limit is 5000 characters currently");
      return;
    }

    setLoading(true); // Start loading

    try {
      let requestBody = {};
      let url = "";

      if (textValue !== "" && uploadedFileId === "") {
        requestBody = {
          script: textValue,
          engine_name: selectedEngine.engine_name.toLowerCase(),
          voice_id: selectedVoice.service_voice_id,
          language_code: selectedVoice.language_code,
          voice_repo_id: selectedVoice.id,
        };

        url = "https://api.dhwani.xyz/secure/synthesize";
      } else if (uploadedFileId !== "" && textValue === "") {
        requestBody = {
          script: "",
          engine_name: selectedEngine.engine_name.toLowerCase(),
          voice_id: selectedVoice.service_voice_id,
          language_code: selectedVoice.language_code,
          voice_repo_id: selectedVoice.id,
          async_execution_id: uploadedFileId,
        };

        url = "https://api.dhwani.xyz/secure/synthesize/large";
      } else {
        // Something is wrong with the selected options. Please refresh and try again

        showNotification("error", "Issue with selected options, Plese refresh and try again");
        return;
      }

      const response = await post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setLoading(false);

      if (!requestBody.async_execution_id || requestBody.async_execution_id === "") {
        // handleSpeechSynthesisComplete(response.data.mp3_url);
        setModalAudioUrl(response.data.mp3_url);
        console.log("modal audio url is now ", response.data.mp3_url)
      }

      if (requestBody.async_execution_id && requestBody.async_execution_id !== "") {
        handleAsyncSpeechSynthesisComplete();
      }
    } catch (error) {
      setLoading(false);

      if (error.response.status === 401) {
        console.log("user is unauthorized here");

        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }

      if (error.response) {
        showNotification("error", error.response.data);
      }
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setselectedLanguageCode(event.target.value.language_code);

    setEngineData(event.target.value.engines);
  };

  const handleEngineChange = (event) => {

    setSelectedEngine(event.target.value);
    setSelectedEngineId(event.target.value.id);

    if (selectedLanguage != "" && event.target.value != "") {
      getVoicesFromLanguageAndEngine(selectedLanguage.language_code, event.target.value.engine_id);
    }
  };

  const handleSuccessMessageClose = () => {
    setModalAudioUrl("")
  }

  const handleAvatarClick = async (voice) => {
    // If clicked on the same voice and it's playing, stop the audio
    if (selectedVoiceId === voice.id && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio
      setSelectedVoiceId(null); // Deselect the voice
      return;
    }

    setSelectedVoice(voice);
    setSelectedVoiceId(voice.id);

    const audio = voice.demo_voice_url;

    if (!audio) {
      // no audio present to play
      showNotification("warning", "No demo present!!!");
      return;
    }

    // Helper function to pause the current audio and wait for it to finish pausing
    const pauseCurrentAudio = async () => {
      if (audioRef.current) {
        await audioRef.current.pause(); // Pause the audio
        audioRef.current.currentTime = 0; // Reset the current audio
      }
    };

    // Wait for the current audio to pause before playing the new one
    await pauseCurrentAudio();

    // Create a new audio element and play it
    audioRef.current = new Audio(audio);
    audioRef.current.play();
  };

  const updateText = (event) => {
    setTextValue(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle dropped files
      console.log(acceptedFiles);
      handleFileUpload(acceptedFiles[0])
    },
    accept: '.txt',
  });

  const { onDrop, ...restProps } = getRootProps();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
      <MDTypography variant="h6" align="center" style={{paddingBottom: "10px"}} gutterBottom>
        Enter text or upload a text file to get started
      </MDTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              placeholder="Enter text less than 5000 characters"
              label="Enter Text"
              variant="outlined"
              aria-label="Text input for entering up to 5000 characters"
              fullWidth
              multiline
              rows={10}
              inputProps={{ maxLength: 1500 }}
              value={textValue}
              onChange={updateText}

              style={{
                backgroundColor: darkMode ? '#2c2c2c' : '#f9f9f9',
              }}
            />
          </Grid>

          <Grid item xs={12} md={1}>
        <Divider orientation="vertical" sx={{ height: '100%', width: '2px' }} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          onDrop={onDrop}
          {...getRootProps()}

          aria-label="Drag and drop a .txt file here, or click to select a file"
          sx={{
            border: '2px dashed #ccc',
            borderRadius: '8px',
            padding: '16px',
            textAlign: 'center',
            cursor: 'pointer',
            minHeight: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: darkMode ? '#2c2c2c' : '#f9f9f9', // Change background color based on dark mode
            color: darkMode ? '#fff' : '#000', // Adjust text color for readability
          }}
        >
          <input {...getInputProps()} />
          <MDTypography variant="body1">
            Drag & drop a .txt file here, or click to select a file
          </MDTypography>
        </Box>

        {fileName && (
          <MDTypography variant="body2" mt={2} aria-live="polite">
            Selected File: {fileName}
          </MDTypography>
        )}
      </Grid>
        </Grid>
        <Grid container style={{paddingTop: "20px"}} spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="select-label">Select a Language</InputLabel>
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  id="select"
                  label="Select a language"
                  labelId="select-label"
                  aria-labelledby="select-label"
                  displayEmpty
                  fullWidth
                  style={{ paddingTop: "8px", paddingBottom: "8px", backgroundColor: darkMode ? '#2c2c2c' : '#f9f9f9', color: darkMode ? '#fff' : '#000' }}
                >
                  {langaugeEngineMapping.map((languageEngineData) => (
                    <MenuItem key={languageEngineData.language_name} value={languageEngineData}>
                      {languageEngineData.language_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <br></br>

              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="select-label-engine">Select an Engine</InputLabel>
                <Select
                  value={selectedEngine}
                  onChange={handleEngineChange}
                  id="select"
                  label="Select an engine"
                  labelId="select-label-engine"
                  aria-labelledby="select-label"
                  displayEmpty
                  fullWidth
                  style={{ paddingTop: "8px", paddingBottom: "8px", backgroundColor: darkMode ? '#2c2c2c' : '#f9f9f9', color: darkMode ? '#fff' : '#000' }}
                >
                  {engineData.map((engine) => (
                    <MenuItem key={engine.engine_name} value={engine}>
                      {engine.engine_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <AvatarContainer>
              {voiceData.map((avatar) => (
                <MDBox
                  key={avatar.name}
                  onClick={() => handleAvatarClick(avatar)}
                  alt={`Voice avatar for ${avatar.name}`}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    margin: "8px",
                  }}
                >
                  <Avatar
                    src={avatar.imageUrl}
                    alt={avatar.name}
                    sx={{
                      bgcolor: selectedVoiceId === avatar.id ? "primary.main" : "grey.300", // Change background color
                      color: selectedVoiceId === avatar.id ? "white" : "black", // Change icon/text color
                    }}
                  />
                  <MDTypography>{avatar.name}</MDTypography>
                </MDBox>
              ))}
            </AvatarContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox p={2} mt="auto">
              <MDButton
                component="button"
                onClick={initiateSpeechSynthesis}
                variant="gradient"
                color="info"
                disabled={loading}
                aria-label={loading ? "Synthesis in progress, please wait" : "Synthesize Speech"}
                fullWidth
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Synthesize Speech"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <AsyncSynthesisModal
          isOpen={isAsyncModalOpen}
          handleClose={handleAsyncModalClose}
          darkMode={darkMode}
        />

      {modalAudioUrl && (
        <SynthesisSuccessContainer handleClose={handleSuccessMessageClose} modalAudioUrl={modalAudioUrl} darkMode={darkMode} />
        )}

      <MDBox sx={{
        marginTop: "40px",
        width: "auto",
        bgcolor: darkMode ? '#222' : '#fff',
        color: darkMode ? '#f1f1f1' : '#000',
        padding: "40px",
        borderRadius: '12px',
        boxShadow: darkMode
          ? '0px 10px 30px rgba(0, 0, 0, 0.5)'
          : '0px 10px 30px rgba(0, 0, 0, 0.2)',
        outline: 'none',
        flexDirection: 'column',  // Arrange children vertically
        position: 'relative',     // Allow absolute positioning
      }}>
      <SampleVoices />
      </MDBox>

      </Container>
      <div style={{ paddingTop: "50px" }}></div>
      
      <Footer />
    </DashboardLayout>
  );
};

export default CoreSpeechLayout;

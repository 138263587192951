import React, { useRef, useState, useEffect } from 'react';

import PropTypes from "prop-types";
import { Modal, Box, Button, Typography, LinearProgress, useTheme, IconButton } from '@mui/material';
import CustomProgressBar from 'components/ProgressBar'
import CloseIcon from '@mui/icons-material/Close';

const AsyncSynthesisModal = ({ isOpen, handleClose, darkMode }) => {
  const theme = useTheme();


  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="synthesis-modal-title">

      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: darkMode ? '#222' : '#fff',
        color: darkMode ? '#f1f1f1' : '#000',
        borderRadius: '12px',
        boxShadow: darkMode
          ? '0px 10px 30px rgba(0, 0, 0, 0.5)'
          : '0px 10px 30px rgba(0, 0, 0, 0.2)',
        padding: theme.spacing(4),
        outline: 'none',
      }}>
                <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: darkMode ? '#fff' : '#000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="synthesis-modal-title" variant="h6" component="h2" sx={{ fontWeight: 700, color: darkMode ? '#ffffff' : '#000000' }}>
          Speech Synthesis In Progress
        </Typography>
        <Typography sx={{ mt: 2, color: darkMode ? '#bbbbbb' : '#555555', lineHeight: 1.6 }}>
          Your request for speech synthesis is in progress and should be completed within the next 2 minutes and will appear on dashboard once ready.
          Please check executions on your dashboard.
        </Typography>
      </Box>
    </Modal>
  );
};

// PropTypes validation
AsyncSynthesisModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  audioUrl: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default AsyncSynthesisModal;

import React from 'react';
import PropTypes from 'prop-types';

const CustomProgressBar = ({ progress }) => {
  const progressStyle = {
    width: `${progress}%`, // Dynamic width based on progress
    backgroundColor: '#007BFF', // Primary blue color
    height: '10px', // Bar height
    borderRadius: '5px', // Rounded corners
    transition: 'width 0.3s ease', // Smooth transition when progress changes
  };

  const containerStyle = {
    width: '100%', // Full width of the parent container
    backgroundColor: '#eee', // Light grey background for the container
    borderRadius: '5px', // Rounded corners for the container
    overflow: 'hidden', // Prevents the bar from overflowing the container
  };

  return (
    <div style={containerStyle}>
      <div style={progressStyle}></div>
    </div>
  );
};

// PropTypes for validation
CustomProgressBar.propTypes = {
  progress: PropTypes.number.isRequired, // Ensure progress is passed as a number
};

export default CustomProgressBar;

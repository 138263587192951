import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { get, post } from "services/api";

import { useNotification } from "context/notification";

// @mui material components
import Grid from "@mui/material/Grid";

import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import CircularProgress from "@mui/material/CircularProgress";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DynamicTable from "components/Tables";
import LearnMoreModal from "./LearnMoreModal";

function Dashboard() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(true);
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false)
  const [dashboardData, setDashboardData] = useState([]);

  const [wordsLeftData, setWordsLeftData] = useState([]);

  const { showNotification } = useNotification();

  const getDashboardDetails = async () => {
    try {
      const response = await get("https://api.dhwani.xyz/secure/dashboard/user", {
        withCredentials: true,
      }); // Replace with your backend endpoint

      setShowLoader(false);
      const dashboardDataResponse = response.data;

      let wordsLeftArr = [dashboardDataResponse.words_left];

      setWordsLeftData(wordsLeftArr);

      setDashboardData(dashboardDataResponse);
    } catch (error) {
      setShowLoader(false);
      console.log("error is ", error);
      if (error.response.status === 401) {
        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }

      showNotification(
        "error",
        "Some error occurred. Please logout and login again if issue persists"
      );
      console.error("Error fetching dashboard:", error);
    }
  };

  const setLearnMoreOpen = () => {
    setIsLearnMoreModalOpen(true)
  }

  const setLearnMoreClose = () => {
    setIsLearnMoreModalOpen(false)
  }

  useEffect(() => {
    getDashboardDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showLoader && (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height to ensure centering
          }}
        >
          <CircularProgress />
        </MDBox>
      )}
      <MDBox mb={3}>
        {dashboardData.words_left ? (
          <>
            <Grid container spacing={3} style={{ paddingTop: "36px" }}>
              <Grid item xs={12} lg={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Words Left
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DynamicTable data={wordsLeftData} source="words_left" />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ paddingTop: "36px" }}>
              <Grid item xs={12} lg={12}>
                <Card style={{ padding: "10px", 
        color: darkMode ? '#f1f1f1' : '#000', }}>
                <MDTypography variant="h6" style={{paddingBottom: "10px"}}>
                  Learn more about how words are deducted across engines:
                </MDTypography>
                <MDButton onClick={() => setLearnMoreOpen(true)} color="info">Learn More</MDButton>
                <LearnMoreModal isOpen={isLearnMoreModalOpen} handleClose={setLearnMoreClose} darkMode={darkMode} />
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </MDBox>
      <MDBox pt={6} pb={3}>
        {dashboardData.executions != null && dashboardData.executions.length > 0 ? (
          <Grid container spacing={6} style={{ paddingBottom: "30px" }}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Executions
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DynamicTable data={dashboardData.executions} source="executions" />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {dashboardData.subscriptions != null && dashboardData.subscriptions.length > 0 ? (
          <Grid container spacing={6} style={{ paddingTop: "30px" }}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Subscriptions
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DynamicTable data={dashboardData.subscriptions} source="subscriptions" />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

import React, { useState, useRef } from "react";
import { Avatar, Grid, IconButton } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const SampleVoices = () => {
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null); // Track the currently playing URL
  const audioRef = useRef(null); // Reference to the audio element

  const handlePlay = (url) => {
    // If the same audio is clicked and is already playing, pause it
    if (currentAudioUrl === url && !audioRef.current.paused) {
      audioRef.current.pause();
      setCurrentAudioUrl(null); // Reset the current playing URL
    } else {
      if (audioRef.current) {
        audioRef.current.pause(); // Pause any currently playing audio
      }
      setCurrentAudioUrl(url); // Update the current playing URL
      audioRef.current.src = url;
      audioRef.current.play();
    }
  };

  // Event handler for when the audio ends
  const handleAudioEnded = () => {
    setCurrentAudioUrl(null); // Reset the current playing URL when audio ends
  };

  return (
    <MDBox>
      <div className="engine-section">
        <MDTypography variant="h5" className="engine-title font-alt" style={{paddingBottom: "20px"}}>
          Samples from Different Engines
        </MDTypography>

        <Grid container spacing={2}>
          {[
            {
              name: "Kendra",
              img: "https://dhwani-assets.b-cdn.net/avatars/avatar-1-female.png",
              url: "https://dhwani-landing-demos.b-cdn.net/standard-english-kendra.mp3",
              engine: "Standard",
              tts_info: "1 word = 1 standard word",
              level: "Good"
            },
            {
              name: "Stephen",
              img: "https://dhwani-assets.b-cdn.net/avatars/avatar-8.png",
              url: "https://dhwani-landing-demos.b-cdn.net/neural-english-stephen.mp3",
              engine: "Neural",
              tts_info: "1 word = 4 standard words",
              level: "Better"
            },
            {
              name: "Gregory",
              img: "https://dhwani-assets.b-cdn.net/avatars/avatar-9.png",
              url: "https://dhwani-landing-demos.b-cdn.net/longform-english-gregory.mp3",
              engine: "Long Form",
              tts_info: "1 word = 25 standard words",
              level: "Best"
            },
            {
              name: "Ruth",
              img: "https://dhwani-assets.b-cdn.net/avatars/avatar-9-female.png",
              url: "https://dhwani-landing-demos.b-cdn.net/generative-english-ruth.mp3",
              engine: "Generative",
              tts_info: "1 word = 8 standard words",
              level: "Best"
            }
          ].map((voice, index) => (
            <Grid item xs={6} md={3} lg={3} key={voice.name}>
              <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center", position: 'relative' }}>
                {/* Avatar */}
                <Avatar
                  src={voice.img}
                  alt={voice.name}
                  sx={{ width: 80, height: 80, cursor: 'pointer', position: 'relative' }}
                  onClick={() => handlePlay(voice.url)} // Play audio when avatar is clicked
                />
                
                {/* Play/Pause Icon Button on top of the Avatar */}
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    width: 30,
                    height: 30,
                  }}
                  onClick={() => handlePlay(voice.url)}
                >
                  {/* Display Play or Pause icon based on whether this avatar's URL matches the currently playing URL */}
                  {currentAudioUrl === voice.url ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                </IconButton>

                <MDTypography variant="subtitle1" className="voice-name font-alt" align="center">
                  {voice.name}
                </MDTypography>
                <MDTypography variant="body" className="voice-name font-alt" align="center">
                  {voice.engine} <MDTypography variant="p" style={{fontSize: "16px"}}>
                  {"("}{voice.level}{")"}
                  </MDTypography>
                </MDTypography>
                <MDTypography variant="body2" className="voice-name font-alt" align="center">
                  {voice.tts_info}
                </MDTypography>
              </MDBox>
            </Grid>
          ))}
        </Grid>

        {/* Hidden audio element to play selected audio */}
        <audio ref={audioRef} onEnded={handleAudioEnded} />
      </div>
    </MDBox>
  );
};

export default SampleVoices;

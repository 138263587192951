import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://api.dhwani.xyz/v1', // Set your base URL here
  baseURL: "https://api.dhwani.xyz",
  timeout: 10000, // Set a timeout if needed
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status >= 400 && error.response.status < 500) {
        // Handle client errors
        console.error('Client Error', error.response.status, error.response.data);
      } else if (error.response.status >= 500) {
        // Handle server errors
        console.error('Server Error', error.response.status, error.response.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    return Promise.reject(error);
  }
);

export const get = (url, config = {}) => {
  return api.get(url, config);
};

export const post = (url, data, config = {}) => {
  return api.post(url, data, config);
};

// Add more methods (put, delete, etc.) as needed

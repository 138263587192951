// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { get, post } from "services/api";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";

import { useEffect, useState, useContext } from "react";

function Overview() {
  const [profileData, setProfileData] = useState({});

  const getUserInfo = async () => {
    console.log("trying to call getUserInfo");

    try {
      const respData = await get("https://api.dhwani.xyz/secure/users/info", {
        withCredentials: true,
      });
      console.log("data is ", respData);

      setProfileData(respData.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.log("user is unauthorized here");

        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }
      if(error.response) {
        showNotification("error", error.response.data);
      }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid md={12} xs={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={`${profileData.name}`}
                info={{
                  fullName: `${profileData.name}`,
                  email: `${profileData.email}`,
                  location: `${profileData.country ? profileData.country : ""}`,
                }}
                social={[]}
                // social={[
                //   {
                //     link: "https://www.facebook.com/CreativeTim/",
                //     icon: <FacebookIcon />,
                //     color: "facebook",
                //   },
                //   {
                //     link: "https://twitter.com/creativetim",
                //     icon: <TwitterIcon />,
                //     color: "twitter",
                //   },
                //   {
                //     link: "https://www.instagram.com/creativetimofficial/",
                //     icon: <InstagramIcon />,
                //     color: "instagram",
                //   },
                // ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;

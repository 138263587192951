import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// import { GoogleOAuthProvider } from '@react-oauth/google';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  // <GoogleOAuthProvider clientId="275828416055-geun2553q93e5j8727r3h89tmjo5hfo6.apps.googleusercontent.com">
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  // </GoogleOAuthProvider>
);
